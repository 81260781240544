/*! main.js | Bulkit | CSS Ninja */

/* ==========================================================================
Core JS file
========================================================================== */

"use strict";

//1. Preload page
// initPageLoader()

jQuery(function ($) {

	//2. Lazy loading
	const el = document.querySelectorAll('[data-lazy-load]');
    const observer = lozad(el, {
			loaded: function(el) {
					// Custom implementation on a loaded element
					el.parentNode.classList.add('loaded');
		}
	});

	$('.providers-caraousel-wrapper').slick({
		draggable: true,
		infinite: true,
		slidesToShow: 6,
		slidesToScroll: 6,
		centerMode: true,
		autoplay: true,
		arrows: false,
		autoplaySpeed: 2500,
		cssEase: 'linear',
		focusOnSelect: true,
		pauseOnFocus: true,
		pauseOnHover:true,
		responsive: [
			{
				breakpoint: 1441,
				settings: {
					slidesToShow: 4,
					slidesToScroll: 4
				}
			},
			{
				breakpoint: 1201,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 3
				}
			},
			{
				breakpoint: 769,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2
				}
			},
			{
				breakpoint: 481,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1
				}
			}
		]
	});
	
  observer.observe();

	//4. Init Feather icons
	feather.replace();

	//5. Init Layout
	initNavbar();
	initLandingNavbar();
	initMobileMenu();
	initLandingMobileMenu();
	initEcommerceNavbar();
	initNavbarDropdown();
	// initSidebar();
	// initThemeSwitcher();
	// initBackgroundImages();

	//6. Components
	initSlider();
	initDropdowns();
	initTabsNav();
	initNavigationTabs();
	initVerticalTabs();
	initMediaCards();
	initTiltCards();
	initPopovers();
	initTooltips();
	initModals();
	initCounters();
	initSimpleAccordion();
	initAccordions();
	initToasts();
	initCountdown();

	//7. Carousels
	initBasicCarousel();
	initVerticalCarousel();
	initFlatCarousel();
	initImageCarousel();
	initSingleImageCarousel();
	initMultipleImagesCarousel();
	
	//8. Forms
	initDatepicker();
	initTimepicker();
	initDatepickerAlt();
	initChosenSelects();
	initMaterialSelect();
	initAutocompletes();
	initFileInputs();
	initRangeInput();
	initRangeInputs();
	initJqueryTagInput();
	initBulmaTags();
	initBulmaSteps();
	initBulmaIconpicker();
	initBulmaCalendar();
	initComboBox();
	initImageComboBox();
	initStackedComboBox();
	initFileUploader();
	
	//9. Video
	// initVideoEmbed();
	// initBackgroundVideo();
	// initPlayers();
	
	//10. Demo
	initDemo();
	initScrollspyNav();
	initParallax();
	initBackToTop();
	
	//11. Utility functions
	initGitem();
	initAnchorScroll();
	initQuickview();
	initScrollReveal();
	initMarquee();
	
	//12. Page specific methods
	initMockup();
	initClientsCarousel();
	initPeopleCarousel();
	initCustomCarousel();
	initCarousel();
	initLandingCarousel();
	initTestimonials();
	initCharacterTestimonials();
	initPricing();
	initPricingCarousel();
	initTabbedPricing();
	initFreelancerPricing();
	initSwitchPricing();
	initBoxedPricing();
	initOnePagePricing();
	//initBlog();
	initSearchBox();
	initNavigationDots();
	initFaq();
	initAuth();
	initAnimations();
	initCanvas();
	initParticles();
	initAnimatedSvg();
	initChatWidget();
	initContactToggler();
	initGoogleMap();
	initMapBox();
	initCodeTabs();
})

console.info(`%c
 _____      _ _  _                 
|  ___|   _| | |(_) __ _ _   _ ___ 
| |_ | | | | | || |/ _\` | | | / __|
|  _|| |_| | | || | (_| | |_| \\__ \\
|_|   \\__,_|_|_|/ |\\__,_|\\__,_|___/
              |__/                 

Nos encanta sumar gente curiosa como vos a nuestra cultura. Escribinos a: rrhh@fulljaus.com.\n
							`, 'font-family: monospace');